import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as gtv from '@arxis/gtvzone';
// eslint-disable-next-line no-restricted-imports
import * as jQuery from 'jquery';

import { DeviceKS } from '../device-ks/device-ks';
import { MetricsService } from '../metrics/metrics.service';
import { RootScopeAdapter } from '../root-scope-adapter/root-scope-adapter';
import { defaultActionFunction, IActionMapping, IKeyAction, IKeyFunction, IKeyMapping, KeyActions } from './gtv.types';

@Injectable()
export class GtvZone {
  timer;
  hidenBtns;
  noAction: IKeyAction = {
    status: KeyActions.None,
  };
  skipAction: IKeyAction = {
    status: KeyActions.Skip,
  };
  selectAction: IKeyAction = {
    status: KeyActions.Selected,
  };
  key = [0, 0, 0, 0];
  keyInit = [0, 0, 0, 0];
  order = 0;

  generalKeymapping: IKeyMapping;

  defaultBackKeyMapping: IKeyMapping = {
    107: this.backFunction.bind(this),
    461: this.backFunction.bind(this),
    10009: this.backFunction.bind(this),
    8: this.backFunction.bind(this),
    121: this.backFunction.bind(this),
  };

  defaultArrowsKeyMapping: IKeyMapping = {
    40: defaultActionFunction, // down
    37: defaultActionFunction, // left
    38: defaultActionFunction, // up
    39: defaultActionFunction, // right
  };

  defaultPlayPauseKeyMapping: IKeyMapping = {
    19: defaultActionFunction, // pause
    413: defaultActionFunction, // pause
    415: defaultActionFunction, // plays
  };

  defaultColorsKeyMapping: IKeyMapping = {
    403: (selectedItem: any, newSelected: any) => {
      // RED 403 - 406
      this.key[this.order] = 403;
      this.order++;
      return this.noAction;
    },
    404: (selectedItem: any, newSelected: any) => {
      // green
      this.key[this.order] = 404;
      this.order++;
      return this.noAction;
    },
    405: (selectedItem: any, newSelected: any) => {
      // yellow
      this.key[this.order] = 405;
      this.order++;
      return this.noAction;
    },
    406: (selectedItem: any, newSelected: any) => {
      // blue
      this.key[this.order] = 406;
      this.order = 0;
      return this.noAction;
    },
  };

  layers: string[] = [];
  zones: { [key: string]: any } = {};

  private globalKeyMappingController: any = null;

  constructor(
    private _ngZone: NgZone,
    private metrics: MetricsService,
    private route: ActivatedRoute,
    private location: Location,
    private deviceKS: DeviceKS,
    private rootScopeAdapter: RootScopeAdapter
  ) {
    this.generalKeymapping = {
      13: (selectedItem: any, newSelected: any) => {
        // con esto disparamos una accion que está implementado un poco mas abajo en Click
        const nativeSelectectItem: HTMLElement = selectedItem[0];
        try {
          nativeSelectectItem.click();
        } catch (e) {
          const evt: Event = document.createEvent('HTMLEvents');
          evt.initEvent('click', true, true);
          nativeSelectectItem.dispatchEvent(evt);
          // selectedItem[0].trigger('click');
        }
        // este return debe estar siempre,
        // solo varia cuando se quiere hacer cosas
        // especiales que no se harán para este proyecto
        return this.selectAction;
      },
      ...this.defaultBackKeyMapping,
      ...this.defaultArrowsKeyMapping,
      ...this.defaultPlayPauseKeyMapping,
      ...this.defaultColorsKeyMapping,
    };

    this._ngZone.runOutsideAngular(() => {
      this.globalKeyMappingController = new gtv.jq.KeyController();
      this.globalKeyMappingController.start();
    });
  }

  buildBackKeyMapping(callBackFunction: IKeyFunction): { [key: number]: IKeyFunction } {
    return {
      107: callBackFunction,
      461: callBackFunction,
      10009: callBackFunction,
      8: callBackFunction,
      121: callBackFunction,
    };
  }

  buildArrowKeysMapping({
    up: upFunction = defaultActionFunction,
    down: downFunction = defaultActionFunction,
    left: leftFunction = defaultActionFunction,
    right: rightFunction = defaultActionFunction,
  }): { [key: number]: IKeyFunction } {
    return {
      37: leftFunction, // left
      38: upFunction, // up
      39: rightFunction, // right
      40: downFunction, // down
    };
  }

  buildPlayPauseKeyMapping(): { [key: number]: IKeyFunction } {
    return {
      19: defaultActionFunction, // pause
      413: defaultActionFunction, // pause
      415: defaultActionFunction, // plays
    };
  }

  verifyOrder() {
    const orderCorrect = [403, 404, 405, 406];
    let band = true;

    for (let i = 0; i < orderCorrect.length; i++) {
      if (this.key[i] !== orderCorrect[i]) {
        band = false;
      }
    }
    this.order = 0;
    this.key = this.keyInit;
    return band;
  }

  backFunction(selectedItem: any, newSelected: any): IKeyAction {
    this._ngZone.run(() => {
      // back Simbolo de + Angular test;
      const currentLocation = this.location.path().split('?')[0];
      if (currentLocation === '') {
        this.rootScopeAdapter.goOutApp.next(true);
      } else if (currentLocation.indexOf('welcome') !== -1) {
        if (this.deviceKS.devicePlatform) {
          // this.device.devicePlatform.exit();
          this.rootScopeAdapter.goOutApp.next(true);
        } else {
          window.history.go(-999);
          window.close();
        }
      } else {
        (window as any).history.back();
      }
    });
    return this.skipAction;
  }

  createZone(
    name: string,
    _zone: string,
    selector: string,
    keyActions: {
      up?: IKeyFunction;
      down?: IKeyFunction;
      left?: IKeyFunction;
      right?: IKeyFunction;
      back?: IKeyFunction;
      additionalKeys?: { [key: number]: IKeyFunction };
    },
    actionMapping: IActionMapping = {},
    navSelectors?: any
  ) {
    const keyMapping = {
      ...this.buildBackKeyMapping(keyActions.back || this.backFunction.bind(this)),
      ...this.buildArrowKeysMapping({
        up: keyActions.up || defaultActionFunction,
        down: keyActions.down || defaultActionFunction,
        left: keyActions.left || defaultActionFunction,
        right: keyActions.right || defaultActionFunction,
      }),
      ...(keyActions.additionalKeys || {}),
    };
    this.zone(name, _zone, selector, keyMapping, actionMapping, navSelectors);
  }

  /**
   * @deprecated use createZone instead
   */
  zone(
    name: string,
    _zone: string,
    selector: string,
    keyMapping: IKeyMapping,
    actionMapping: IActionMapping,
    navSelectors?: any
  ) {
    this._ngZone.runOutsideAngular(() => {
      try {
        for (const key in this.generalKeymapping) {
          if (!keyMapping[key]) {
            keyMapping[key] = this.generalKeymapping[key];
          }
        }
        if (this.zones[_zone]) {
          this.globalKeyMappingController.removeBehaviorZone(this.zones[_zone]);
        }
        this.zones[_zone] = this.createNewKeyBehaviorZone(selector, keyMapping, actionMapping, navSelectors);
        if (this.layers.indexOf(name) === -1) {
          this.layers.push(name);
        }

        this.globalKeyMappingController.addBehaviorZone(this.zones[_zone], true, this.layers);
        return this;
      } catch (e) {
        // console.log('ocurrio erro en mapeo',e);
        return null;
      }
    });
  }
  removeZone(zoneName: any) {
    const l = this.zones[zoneName];
    if (!!l) {
      this.globalKeyMappingController.removeBehaviorZone(l);
    }
  }

  skipFunction: IKeyFunction = (selectedItem: any, newSelected: any) => this.skipAction;
  noActionFunction: IKeyFunction = (selectedItem: any, newSelected: any) => this.noAction;
  moveFunction: IKeyFunction = (selectedItem: any, newSelected: any) => {
    if (!newSelected || (newSelected && newSelected.length === 0)) {
      return this.skipAction;
    }
    return this.noAction;
  };

  moveOrMoveToZone: (zoneName: string) => IKeyFunction = (zoneName: string) => (selected: any, newSelected: any) => {
    if (!newSelected || (newSelected && newSelected.length === 0)) {
      this.globalKeyMappingController.setZone(this.zones[zoneName], true);
      return this.skipAction;
    }
    return this.noAction;
  };

  moveOrCallback: (callBack: () => any) => IKeyFunction =
    (callBack: () => any) => (selected: any, newSelected: any) => {
      if (!newSelected || (newSelected && newSelected.length === 0)) {
        callBack();
        return this.skipAction;
      }
      return this.noAction;
    };
  createNewKeyBehaviorZone(selector: string, keyMapping: IKeyMapping, actionMapping: any, navSelectors: any) {
    return new gtv.jq.KeyBehaviorZone({
      containerSelector: selector,
      navSelectors: (navSelectors && navSelectors.itemRow) || {
        itemRow: '.keyboard-row',
        itemParent: '.keyboard-parent',
        item: '.item-keyboard-v2',
        itemPage: null,
      },
      selectionClasses: {
        basic: 'focus-item-keyboard',
        hasData: 'focus-item-keyboard',
      },
      saveRowPosition: false,
      keyMapping,
      actions: actionMapping || {},
      useGeometry: true,
    });
  }

  setZone(zone: any) {
    try {
      if (!!this.zones[zone]) {
        this.globalKeyMappingController.setZone(this.zones[zone], true);
      }
    } catch (e) {
      this.sendMappingDown('mappingDown', e, zone, zone);
    }
  }
  setDefaultSelected(element: any, zone?: string) {
    element = jQuery(element);
    try {
      if (zone) {
        this.globalKeyMappingController.setZone(this.zones[zone], true);
      }

      this.globalKeyMappingController.setSelected(element);
    } catch (e) {
      const viewCurrent = this.route.snapshot.url.toString();
      if (
        element.selector &&
        element.selector.indexOf('#song-') === -1 &&
        element.selector.indexOf('.first-key-login') === -1
      ) {
        this.sendMappingDown('mappingDown', e, element, zone);
      }

      setTimeout(() => {
        const focusedElement = document.querySelector('.focus-item-keyboard');

        if (!focusedElement) {
          this.sendMappingDown('realPosibleMappingDown', e, element, zone, {
            init: viewCurrent,
            currentView: this.route.snapshot.url.toString(),
          });
        }
      }, 5000);
    }
  }
  sendMappingDown(ev: string, e: any, element: any, zone: any, views?: any) {
    const dataForEvent = {
      error: e.toString(),
      selector: element.selector,
      zone,
      view: this.route.snapshot.url.toString(),
      viewUrl: this.route.snapshot.url.toString(),
    };
    if (views) {
      dataForEvent['differentViewForRealMappingDown'] = views.init !== views.currentView;
      dataForEvent['init'] = views.init;
      dataForEvent['currentView'] = views.currentView;
    }
    console.log('sendMappingDown', dataForEvent);
    this.metrics.sendEvent(ev, dataForEvent);
  }
}
